
import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import IndexNavbar from "../../Navbars/IndexNavbar";
import FormHeader from "../../Headers/FormHeader";
import { Link } from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Container} from "reactstrap";

class DbEditorMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
        };
    }

    render() {
        return (
        <>
            <IndexNavbar />
            <FormHeader title={"Rogue Search Menu"} lead="Hey there! This is our new manual identifier classification tool. First, download your data, then upload your edited results! It's that easy!" />
            <Container fluid className="mt--8 pb-5">
                <div className="header-body">
                    <Row className="justify-content-center">
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/identifier-send" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Choose your company and marketplace
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">Receive link to download data</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i className="ni ni-send" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* Parse through the most recently retrieved sunday listings to see if they're related to the specified brand */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/identifier-upload" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Upload your edited excel sheet
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">Upload identifier classifications</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                                            <i className="ni ni-cloud-upload-96" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* View all pdp listings that have been uploaded to the database. (under construction)  */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                        
                        
                    </Row>
                </div>
            </Container>
        </>
        )
    }
}

export default DbEditorMenu;