import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Unauthorized from "./components/auth/Unauthorized";
import { getRoles } from "./helpers/auth";

import ListingExtraction from "./components/tools/ListingExtraction";
import SellerDataExtraction from "./components/tools/SellerDataExtraction";
import ViolationSubmission from "./components/tools/ViolationSubmission";
import UploadComplete from "./components/dashboard/UploadComplete";
import UnderConstruction from "./components/tools/UnderConstruction";
import Dashboard from "./components/dashboard/Dashboard";
import StatusPage from "./components/tools/StatusPage";
import DbEditorMenu from "./components/tools/DBEditor/DBEditorMenu"; 
import UpdateASINs from "./components/tools/DBEditor/UpdateASINs"; 
import UpdateApprovedSellers from "./components/tools/DBEditor/UpdateApprovedSellers";
import InsertStandAloneImages from "./components/tools/DBEditor/InsertStandAloneImages";
import DBStatusPage from "./components/tools/DBEditor/DBStatusPage";
import AutoCreateMenu from "./components/tools/AutoCreateAccounts/AutoCreateMenu";
import AutoCreateAmazonAccounts from "./components/tools/AutoCreateAccounts/AutoCreateAmazonTool";
import AutoCreateWalmartAccounts from "./components/tools/AutoCreateAccounts/AutoCreateWalmartTool";
import "./assets/css/new-identifier-search.css";
import { auth } from "./services/firebase";
import IdentifierSearch from "./components/tools/IdentifierSearch/IdentifierSearchMenu";
import IdentifierUpload from "./components/tools/IdentifierSearch/IdentifierUpload";
import IdentifierSend from "./components/tools/IdentifierSearch/identifierSend";
import SuccessPage from "./components/dashboard/SuccessPage";
import CopyrightComparisons from "./components/tools/CopyrightComparisons/CopyrightComparisonsMenu";
import CopyrightComparisonsSend from "./components/tools/CopyrightComparisons/CopyrightComparisonsSend";
import CopyrightComparisonsUpload from "./components/tools/CopyrightComparisons/CopyrightComparisonsUpload";

import ExtCopyrightComparisons from "./components/tools/ExtCopyrightComparisons/ExtCopyrightComparisonsMenu";
import ExtCopyrightComparisonsSend from "./components/tools/ExtCopyrightComparisons/ExtCopyrightComparisonsSend";
import ExtCopyrightComparisonsUpload from "./components/tools/ExtCopyrightComparisons/ExtCopyrightComparisonsUpload";

//import NewIdentifierSearch from "./components/tools/NewIdentifierSearch/NewIdentifierSearch"; depreciated


// require('dotenv').config({path: __dirname + '/.env'});

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

// check to make sure a user has a valid role to access a page
function RoleRoute({
  component: Component,
  authenticated,
  acceptedRoles,
  roles,
  ...rest
}) {
  var validRole = false;
  if (authenticated === true) {
    const roleList = acceptedRoles.split(",");

    roles.forEach((role) => {
      console.log(roles)
      if (roleList.includes(role)) {
        validRole = true;
      }
    });
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true && validRole === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  };

  componentDidMount() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        const roles = getRoles();

        this.setState({
          authenticated: true,
          loading: false,
          roles: roles,
        });
      } else {
        this.setState({
          authenticated: false,
          loading: false,
        });
      }
    });
  };

  render() {
    return this.state.loading === true ? (
      <div class="text-center align-middle">
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      <>
      <Router>
        <Switch>
          {/* <Route exact path="/" render={props => <Index {...props} />} /> */}
          <PrivateRoute
            exact
            path="/"
            authenticated={this.state.authenticated}
            component={Dashboard}
          />
          <PrivateRoute
            path="/dashboard"
            authenticated={this.state.authenticated}
            component={Dashboard}
          />
          <PrivateRoute
            path="/uploadcomplete"
            authenticated={this.state.authenticated}
            component={UploadComplete}
          />
          <PrivateRoute
            path="/unauthorized"
            authenticated={this.state.authenticated}
            component={Unauthorized}
          />
          <PrivateRoute
            path="/under-construction"
            authenticated={this.state.authenticated}
            component={UnderConstruction}
          />
          <RoleRoute
            path="/history"
            authenticated={this.state.authenticated}
            acceptedRoles="listingextraction,violationsubmission,admin,seller-data-extraction"
            roles={this.state.roles}
            component={StatusPage}
          />
          <RoleRoute
            path="/listingextraction"
            authenticated={this.state.authenticated}
            acceptedRoles="listingextraction,admin"
            roles={this.state.roles}
            component={ListingExtraction}
              />
          <RoleRoute
            path="/seller-data-extraction"
            authenticated={this.state.authenticated}
            acceptedRoles="seller-data-extraction,admin"
            roles={this.state.roles}
            component={SellerDataExtraction}
          />
          <RoleRoute
            path="/violationsubmission"
            authenticated={this.state.authenticated}
            acceptedRoles="violationsubmission,admin"
            roles={this.state.roles}
            component={ViolationSubmission}
          />
          <RoleRoute
            path="/db-editor"
            authenticated={this.state.authenticated}
            acceptedRoles="dbeditor,admin"
            roles={this.state.roles}
            component={DbEditorMenu}
          />
          <RoleRoute
            path="/update-asins"
            authenticated={this.state.authenticated}
            acceptedRoles="dbeditor,admin"
            roles={this.state.roles}
            component={UpdateASINs}
          />
          <RoleRoute
            path="/update-apr-sellers"
            authenticated={this.state.authenticated}
            acceptedRoles="dbeditor,admin"
            roles={this.state.roles}
            component={UpdateApprovedSellers}
          />
          <RoleRoute
            path="/stand-alone-images"
            authenticated={this.state.authenticated}
            acceptedRoles="dbeditor,admin"
            roles={this.state.roles}
            component={InsertStandAloneImages}
          />
          <RoleRoute
            path="/db-status"
            authenticated={this.state.authenticated}
            acceptedRoles="dbeditor,admin"
            roles={this.state.roles}
            component={DBStatusPage}
          />
          <RoleRoute
            path="/identifier-search"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={IdentifierSearch}
          />
          <RoleRoute
            path="/identifier-upload"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={IdentifierUpload}
          />
          <RoleRoute
            path="/identifier-send"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={IdentifierSend}
          />
          <RoleRoute
            path="/copyright-comparisons"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={CopyrightComparisons}
          />
           <RoleRoute
            path="/copyright-send"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={CopyrightComparisonsSend}
          />
          <RoleRoute
            path="/copyright-upload"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={CopyrightComparisonsUpload}
          />
          <RoleRoute
            path="/ext-copyright-comparisons"
            authenticated={this.state.authenticated}
            acceptedRoles={"admin, crtm"}
            roles={this.state.roles}
            component={ExtCopyrightComparisons}
          />
           <RoleRoute
            path="/ext-copyright-send"
            authenticated={this.state.authenticated}
            acceptedRoles={"admin, crtm"}
            roles={this.state.roles}
            component={ExtCopyrightComparisonsSend}
          />
          <RoleRoute
            path="/ext-copyright-upload"
            authenticated={this.state.authenticated}
            acceptedRoles={"admin, crtm"}
            roles={this.state.roles}
            component={ExtCopyrightComparisonsUpload}
          />

          {/* <RoleRoute
            path="/new-identifier-search"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,admin"}
            roles={this.state.roles}
            component={NewIdentifierSearch}
          /> */}
          {/* <PrivateRoute
            path="/new-identifier-search"
            authenticated={this.state.authenticated}
            component={NewIdentifierSearch}
          /> */}
          <RoleRoute
            path="/auto-create-accounts"
            authenticated={this.state.authenticated}
            acceptedRoles="auto-create-amazon-accounts,auto-create-walmart-accounts,admin"
            roles={this.state.roles}
            component={AutoCreateMenu}
          />
          <RoleRoute
            path="/auto-create-amazon-accounts"
            authenticated={this.state.authenticated}
            acceptedRoles="auto-create-amazon-accounts,admin"
            roles={this.state.roles}
            component={AutoCreateAmazonAccounts}
          />
          <RoleRoute
            path="/auto-create-walmart-accounts"
            authenticated={this.state.authenticated}
            acceptedRoles="auto-create-walmart-accounts,admin"
            roles={this.state.roles}
            component={AutoCreateWalmartAccounts}
          />
          <PublicRoute
            path="/register"
            authenticated={this.state.authenticated}
            component={Register}
          />
          <PublicRoute
            path="/signin"
            authenticated={this.state.authenticated}
            component={Login}
          />
          <RoleRoute
            path="/success"
            authenticated={this.state.authenticated}
            acceptedRoles={"nifeditor,dbeditor,admin"}
            roles={this.state.roles}
            component={SuccessPage}
          />
        </Switch>
      </Router>
      </>
    );
  }
}

export default App;
