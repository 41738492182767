import { auth } from "../services/firebase";
import { db } from "../services/firebase";

export function signup(email, password) {
  return auth().createUserWithEmailAndPassword(email, password);
}

export function assignName (user, name) {
  return user.updateProfile({
    displayName: name
  })
}

export function signin(email, password) {
  return auth().signInWithEmailAndPassword(email, password);
}

export function signInWithGoogle() {
  const provider = new auth.GoogleAuthProvider();
  return auth().signInWithPopup(provider);
}

export function signInWithGitHub() {
  const provider = new auth.GithubAuthProvider();
  return auth().signInWithPopup(provider);
}

export function logout() {
  return auth().signOut();
}

export function forgotPassword() {
  return auth().sendPasswordResetEmail();
}

export function getRoles() {
  const uid = auth().currentUser.uid;
  var roles = [];

  db.ref("roles/" + uid).once("value").then(function (snapshot) {
    snapshot.forEach((child) => {
      roles.push(child.key);
    })
  });
    

    // console.log(roles)

  return roles;

    //     roles.forEach((role) => {
    //       console.log(role);
    //     });

    // console.log(roles.length)
    // console.log(roles)

    // return roles;
  }

