/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { auth } from "../../services/firebase";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import FormHeader from "../Headers/FormHeader.js";
import IndexNavbar from "../Navbars/IndexNavbar.js";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };
  }
  render() {
    var user = this.state.user.email;

    try {
      user = this.state.user.displayName;
      // console.log(this.state.user.displayName);
    } catch (err) {
      console.log(err);
    }
    // console.log(user.displayName);
    var s = `Hey there!`;
    try {
      s = `Hey there, ${user.split(" ")[0]}`;
    } catch (err) {}
    return (
      <>
        <IndexNavbar />
        <FormHeader title={s} lead="Choose an option below to get started" />
        <Container fluid className="mt--8 pb-5">
          <div className="header-body">
            <Row className="justify-content-center">
              <Col md="3" xl="3">
                <Card className="card-stats" to="/listingextraction" tag={Link}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Amazon / Walmart
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          Listing Extractor
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-basket" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        Use a CSV/Excel file to extract current active listings
                        from Amazon or Walmart products
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" xl="3">
                <Card
                  className="card-stats"
                  to="/violationsubmission"
                  tag={Link}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Amazon
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          Violation Submitter
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="ni ni-support-16" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        Use a CSV/Excel file to submit violations to Amazon
                        Brand Registry
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md="3" xl="3">
                <Card
                  className="card-stats"
                  to="/auto-create-accounts"
                  tag={Link}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Amazon / Walmart
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          Auto Create Accounts
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-teal text-white rounded-circle shadow">
                          <i className="ni ni-laptop" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        Request and customize auto-created Amazon or Walmart
                        accounts. Monitor the status of the creation process.
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
            <Row className="justify-content-center">
              <Col md="3" xl="3">
                <Card
                  className="card-stats"
                  to="/identifier-search"
                  tag={Link}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Amazon / Walmart
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          Rogue Search
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-books" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        Download and upload new identifiers as rogue identifiers.
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col> 
            
              <Col md="3" xl="3">
                <Card className="card-stats" to="/db-editor" tag={Link}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        ></CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          Database Editor
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
                          <i className="ni ni-lock-circle-open" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        Use a CSV/Excel file to submit database entries into the
                        databse.
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              {/* <Col md="3" xl="3">
                <Card
                  className="card-stats"
                  to="/new-identifier-search"
                  tag={Link}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Amazon / Walmart
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          New Identifier Search Tool
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-planet" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        Use the New Identifier Search Tool to parse through the
                        most recent search results for rogue identifiers.
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col> */}
              <Col md="3" xl="3">
                <Card className="card-stats" to="/history" tag={Link}>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          All
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          Tool Usage History
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-gradient-pink text-white rounded-circle shadow">
                          <i className="ni ni-archive-2" />
                        </div>
                      </Col>
                    </Row>
                    <CardSubtitle>
                      <p className="mt-3 mb-0 text-sm">
                        View historical tool use, and cancel in-progress tool
                        runs
                      </p>
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
              
                <Col md="3" xl="3">
                  <Card
                    className="card-stats"
                    to="/seller-data-extraction"
                    tag={Link}
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Amazon
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Seller Data Extraction
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                            <i className="ni ni-money-coins" />
                          </div>
                        </Col>
                      </Row>
                      <CardSubtitle>
                        <p className="mt-3 mb-0 text-sm">
                          Extract seller data for a list of brands.
                        </p>
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="3" xl="3">
                  <Card
                    className="card-stats"
                    to="/copyright-comparisons"
                    tag={Link}
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Amazon / Walmart
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Internal Copyright Comparisons
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-gray text-white rounded-circle shadow">
                            <i className="ni ni-image" />
                          </div>
                        </Col>
                      </Row>
                      <CardSubtitle>
                        <p className="mt-3 mb-0 text-sm">
                          Download internal copyright image comparisons.
                        </p>
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              
                <Col md="3" xl="3">
                  <Card
                    className="card-stats"
                    to="/ext-copyright-comparisons"
                    tag={Link}
                  >
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Amazon / Walmart
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            Customer-Ready Copyright Results
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                            <i className="ni ni-image" />
                          </div>
                        </Col>
                      </Row>
                      <CardSubtitle>
                        <p className="mt-3 mb-0 text-sm">
                          Download customer ready copyright image comparisons.
                        </p>
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          </div>
        </Container>
      </>
    );
  }
}
