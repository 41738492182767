import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Spinner
} from "reactstrap";

import FormHeader from "../../Headers/FormHeader.js";
import IndexNavbar from "../../Navbars/IndexNavbar.js";
import { AutoCreateWalmartHandler } from "../../../handlers/AutoCreateWalmartHandler";
import ActiveLogBody from "./ActiveWalmartLogBody";

export default class AutoCreateAccounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
        user: auth().currentUser,
  
        dropdownOpenAccountType: false,
        dropdownOpenCount: false,
        dropdownOpenMarketplace: false,
        dropdownOpenEmailDomain: false,
  
        accountType: "Messaging",
        accountCount: "1",
        accountMarketplace: "United States",
        accountEmailDomain: "Mozmail",
  
        hasActiveRequests: false,
        isRequestComplete: true,
        activeRequestLogs: [],
        displayModal: false,
        displayEndModal: false,
        displayProxyModal: false,
        init: true,
        processing: false,
        requiresUpdate: false,
        numProxiesAvailable: -1,
      };

    this.toggleAccountType = this.toggleAccountType.bind(this);
    this.toggleCount = this.toggleCount.bind(this);
    this.toggleMarketplace = this.toggleMarketplace.bind(this);
    this.toggleEmailDomain = this.toggleEmailDomain.bind(this);

    this.updateAccountType = this.updateAccountType.bind(this);
    this.updateCount = this.updateCount.bind(this);
    this.updateMarketplace = this.updateMarketplace.bind(this);
    this.updateEmailDomain = this.updateEmailDomain.bind(this);

    this.hasActiveRequest = this.hasActiveRequest.bind(this);
    this.getActiveRequestLog = this.getActiveRequestLog.bind(this);
    this.isRequestComplete = this.isRequestComplete.bind(this);
    this.checkAvailableProxies = this.checkAvailableProxies.bind(this);

    this.setModal = this.setModal.bind(this);
    this.setProxyModal = this.setProxyModal.bind(this);
    this.setEndModal = this.setEndModal.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    // this.setSpinner = this.setSpinner.bind(this);
    this.showProcessing = this.showProcessing.bind(this);
    this.endProcessing = this.endProcessing.bind(this);
    this.firstAttemptToSubmitRequest = this.firstAttemptToSubmitRequest.bind(this);

    // this.endLog = this.endLog.bind(this);
    

    //this.isRequestComplete();
  }

    toggleAccountType() {
        this.setState(prevState => ({
            dropdownOpenAccountType: !prevState.dropdownOpenAccountType
        }));
    }

    toggleCount() {
        this.setState(prevState => ({
            dropdownOpenCount: !prevState.dropdownOpenCount
        }));
    }

    toggleMarketplace() {
        this.setState(prevState => ({
            dropdownOpenMarketplace: !prevState.dropdownOpenMarketplace
        }));
    }

    toggleEmailDomain() {
        this.setState(prevState => ({
            dropdownOpenEmailDomain: !prevState.dropdownOpenEmailDomain
        }));
    }

    setModal() {
        this.setState(prevState => ({
            displayModal: !prevState.displayModal,
            displaySpinner: false
        }));
    }

    setProxyModal() {
        this.setState(prevState => ({
            displayProxyModal: !prevState.displayProxyModal
        }));
    }

    setEndModal() {
        this.setState(prevState => ({
            displayEndModal: !prevState.displayEndModal,
            // displaySpinner: false
        }));
    }

    // setSpinner() {
    //     this.setState(prevState => ({
    //         displaySpinner: !prevState.displaySpinner
    //     }));
    // }

    updateAccountType(e) {
        this.setState({
            accountType: e.currentTarget.textContent
        })
    }

    updateCount(e) {
        this.setState({
            accountCount: e.currentTarget.textContent
        })
    }

    updateMarketplace(e) {
        this.setState({
            accountMarketplace: e.currentTarget.textContent
        })
    }

    updateEmailDomain(e) {
        this.setState({
            accountEmailDomain: e.currentTarget.textContent
        });
    }

    refreshPage() {
        this.setModal()
        window.location.replace('/auto-create-walmart-accounts');
    }

    initPage() {
        // console.log(this.state.init)
        if (this.state.init === true) {
            this.hasActiveRequest();
            this.getActiveRequestLog();
            this.setState({
                init: false
            });
        }
    }

    async sleep(ms) {
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
    }
    
        
    async sendAutoCreateRequest() {
        let success = await new AutoCreateWalmartHandler(this.state.user.email).requestAutoAccountCreation(
            this.state.accountCount, 
            this.state.accountType, 
            this.state.accountMarketplace, 
            this.state.accountEmailDomain
        )
        
        if (success){
            this.setModal()
        }
    }
    async checkAvailableProxies() {
        const autoCreateHandler = new AutoCreateWalmartHandler(this.state.user.email);
        let proxyData = await autoCreateHandler.checkAvailableProxies(
            this.state.accountCount,
            this.state.accountMarketplace,
        );
        const proxiesAreAvailable = proxyData[0];
        const amountAvailable = proxyData[1];
        this.setState({
            numProxiesAvailable: amountAvailable
        });
        if (!proxiesAreAvailable) {
            this.setProxyModal()
        }
        else {
            this.sendAutoCreateRequest()
        }
    }

    async hasActiveRequest() {
        const autoCreateHandler = new AutoCreateWalmartHandler(this.state.user.email);
        let activeRequestData = await autoCreateHandler.hasActiveRequest();
        this.setState({
            hasActiveRequests: activeRequestData[0], 
            requiresUpdate: activeRequestData[1]
        });
    }

    async isRequestComplete() {
        const autoCreateHandler = new AutoCreateWalmartHandler(this.state.user.email);
        let isRequestComplete = await autoCreateHandler.isRequestComplete();
        //console.log(isRequestComplete)
        this.setState({
            isRequestComplete: isRequestComplete
        });

        
        if (this.state.isRequestComplete === false) {
            if (this.state.hasActiveRequests === true) {
                // console.log("auto refreshing...")
                await this.sleep(10000)
                this.getActiveRequestLog()
            }
        }
    }

    firstAttemptToEndLogs() {
        this.setEndModal()
    }

    firstAttemptToSubmitRequest() {
        this.setState({
            processing: true
        });
        this.checkAvailableProxies()
    }

    endProcessing() {
        this.setState({
            processing: false,
            displayProxyModal: false
        });
        
    }

    async endLog() {
        const autoCreateHandler = new AutoCreateWalmartHandler(this.state.user.email);
        let success = await autoCreateHandler.endLog();
        if (success) {
            this.setEndModal()
            window.location.replace('/auto-create-walmart-accounts');
        }
        this.setState({
            hasActiveRequests: false
        });
       
        
    }
    
    async getActiveRequestLog() {
        const autoCreateHandler = new AutoCreateWalmartHandler(this.state.user.email);
        let activeLog = await autoCreateHandler.getActiveRequestLog();
        //console.log(activeLog)
        this.setState({
            activeRequestLogs: activeLog
        });
        this.isRequestComplete();
    }

    showProcessing() {
        this.setState({
            processing: true
        });
        this.sendAutoCreateRequest()
    }

    render() {
        const sendRequest = this.sendAutoCreateRequest.bind(this);
        const initPage = this.initPage.bind(this);
        const endLog = this.endLog.bind(this);
        const firstAttemptToEndLogs = this.firstAttemptToEndLogs.bind(this);

        initPage()
        
        var user = this.state.user.email;
        try {
        user = this.state.user.displayName;
        } catch (err) {
        console.log(err);
        }
        let display = null;
        if (this.state.hasActiveRequests === false) {
            display = (
            <>
                <IndexNavbar />
                <FormHeader title="Auto Create WALMART Accounts Tool" />

                <Container className="mt--9 pb-5">
                    
                <Row className="justify-content-center">
                    <Col lg="5" md="7">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardHeader className="bg-transparent">
                        <div className="text-center">
                            <small>
                            Customize your request for auto-creating new Walmart Accounts. 
                            </small>
                        </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                        
                            <div>
                                <small>Number of New Accounts: &emsp;</small>
                                <Dropdown isOpen={this.state.dropdownOpenCount} toggle={this.toggleCount}>
                                    <DropdownToggle caret>
                                        {this.state.accountCount}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>1</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>2</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>3</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>4</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>5</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>6</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>7</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>8</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>9</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateCount}>10</div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <br></br>
                            
                            <div >
                                <small>Account Type &emsp;</small>
                                <Dropdown isOpen={this.state.dropdownOpenAccountType} toggle={this.toggleAccountType}>
                                    <DropdownToggle caret>
                                        {this.state.accountType}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <div onClick={this.updateAccountType}>Messaging</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateAccountType}>Test Buy</div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <br></br>

                            <div >
                                <small>Marketplace &emsp;</small>
                                <Dropdown isOpen={this.state.dropdownOpenMarketplace} toggle={this.toggleMarketplace}>
                                    <DropdownToggle caret>
                                        {this.state.accountMarketplace}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <div onClick={this.updateMarketplace}>United States</div>
                                        </DropdownItem>
                                        {/* <DropdownItem>
                                            <div onClick={this.updateMarketplace}>Canada</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateMarketplace}>United Kingdom</div>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <div onClick={this.updateMarketplace}>Germany</div>
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <br></br>

                            <div >
                                <small>Email Domain &emsp;</small>
                                <Dropdown isOpen={this.state.dropdownOpenEmailDomain} toggle={this.toggleEmailDomain}>
                                    <DropdownToggle caret>
                                        {this.state.accountEmailDomain}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <div onClick={this.updateEmailDomain}>Mozmail</div>
                                        </DropdownItem>
                                        {/* <DropdownItem>
                                            <div onClick={this.updateEmailDomain}>Grayfalkon</div>
                                        </DropdownItem> */}
                                        {/* <DropdownItem>
                                            <div onClick={this.updateEmailDomain}>Mozmail</div>
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <br></br>

                            <div className="text-center">

                            {/* < Button className="my-4" color="info" onClick={() => sendRequest()}> */}
                            {/* < Button className="my-4" color="info" onClick={() => this.showSpinner()}>
                                    Submit Request
                            </Button> */}
                            <Button color="info" onClick={this.firstAttemptToSubmitRequest}>
                                {!this.state.processing ? "Submit Request" : "Loading"} 
                                {this.state.processing ? (
                                    <Spinner className="m-1" color="light" size="sm"
                                    />
                                ) : null }
                            </Button>
                            <Modal isOpen={this.state.displayProxyModal} toggle={this.setProxyModal}>
                                <ModalHeader><b>Insufficient Proxies Available!</b></ModalHeader>
                                    <ModalBody className = "text-danger">
                                       We are unable to submit your request at this time. 
                                        We have notified Support to purchase more proxies for your 
                                        requested marketplace. <br></br><br></br>
                                        Once proxies have been allocated, you will be notified!
                                    </ModalBody>
                                <ModalFooter>
                                <Button color="danger" onClick={this.endProcessing}>
                                    Ok, Thank you!
                                </Button>
                                </ModalFooter>
                            </Modal>
                            
                            <Modal isOpen={this.state.displayModal} toggle={this.setModal}>
                                <ModalHeader >Successful Submission!</ModalHeader>
                                    <ModalBody className = "text-success">
                                    Your request for new Walmart Accounts has been submitted!
                                    You can now view the progress of the request.
                                    </ModalBody>
                                <ModalFooter>
                                <Button color="success" onClick={this.refreshPage}>
                                    View Progress
                                </Button>
                                </ModalFooter>
                            </Modal>

                            </div>
                        {/* </Form> */}
                        </CardBody>
                    </Card>
                    </Col>
                </Row>
                </Container>
            </>)
         } 
         if (this.state.hasActiveRequests === true) {

            if (this.state.isRequestComplete === true) {
                display = (
                    <>
                    <IndexNavbar />
                    {/* <FormHeader title="You have an Active Request!" /> */}
                    {/* <Container className="mt--9 pb-5"></Container> */}
                    <Container className="archive-body rounded mt-5 pt-3 pb-3 pl-5 pr-0 mt-2">
                        <Row className="">
                            <Col>
                                <h1><strong>Your Request is Completed!</strong></h1>
                            </Col>
                            <Col>
                                <Button className="mr-4 float-right" color="info" onClick={() => firstAttemptToEndLogs()}>
                                    Request More Accounts
                                </Button>
                                <Modal isOpen={this.state.displayEndModal} toggle={this.setEndModal}>
                                    <ModalHeader >Continue</ModalHeader>
                                        <ModalBody>
                                        By continuing, you will <b>no longer have access</b> to these logs.
                                        Are you sure you want to continue?
                                        
                                        </ModalBody>
                                        <ModalFooter>
                                        {/* <Button color="warning" onClick={endLog()}> */}
                                        <Button id="end-logging" color="warning" onClick={() => endLog()}>
                                            continue
                                        </Button>
                                        <Button color="info" onClick={this.setEndModal}>
                                            cancel
                                        </Button>
                                        </ModalFooter>
                                </Modal>
                            </Col>
                        </Row>
                        <Row>
                        <ActiveLogBody
                            activeRequests={this.state.activeRequestLogs}
                        />
                        </Row>
                    </Container>
                    </> 
                )
            }
            else {
                display = (
                    <>
                    <IndexNavbar />
                    {/* <FormHeader title="You have an Active Request!" /> */}
                    {/* <Container className="mt--9 pb-5"></Container> */}
                    <Container className="archive-body rounded mt-5 pt-3 pb-3 pl-5 pr-0 mt-2">
                        <Row className="">
                            <Col>
                            <h1><strong>You have an Active Request!</strong></h1>
                            </Col>
                            <Col>
                                <Button className="mr-4 float-right" color="info" onClick={() => this.getActiveRequestLog()}>
                                    Refresh
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                        <ActiveLogBody
                            activeRequests={this.state.activeRequestLogs}
                        />
                        </Row>
                    </Container>
                    </> 
                )
            }
         }
       
        return display
    }
}
