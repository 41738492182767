/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import "./Dashboard.css";

import IndexNavbar from "../Navbars/IndexNavbar.js";
import FormHeader from "../Headers/FormHeader.js";
import { auth } from "../../services/firebase";

export default class UploadComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <FormHeader
          title="Upload Complete!"
          lead="Results or errors will be emailed to the address tied to your account."
        />
      </>
    );
  }
}
