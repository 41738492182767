/**
 * @fileOverview CompanyHandler.js
 * @description File containing class to handle requests primarily related to the Company Table
 */

// Imported Modules
import axios from 'axios';
import { ApiConnection } from "./ApiConnections";

/**
 * @class CompanyHandler
 * @description Class to handle requests related to the company
 * 
 * @constructor
 * @param {string} email - The email of the user making the request, used to determine who broke the page, and find their requests.
 * @property {string} userEmail - The email of the user making the request.
 * @property {ApiConnection} apiConnection - The ApiConnection object used to determine the server connection string.
 */
export class CompanyHandler {
    /**
     * Creates an instance of CompanyHandler.
     * @param {string} email - The user's email.
     */
    constructor(email) {
        this.userEmail = email; // Store the provided email in the userEmail attribute
        this.apiConnection = new ApiConnection(); // Create an instance of the ApiConnection class and store it in the apiConnection attribute
    }

    /**
     * Retrieves a list of companies.
     * @async
     * @returns {Array} The list of companies.
     * @throws {Error} If an error occurs during the request.
     */
    async getAllCompanies() {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/base/all-companies");
            const results = await axios.get(connectionString, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return results.data.companyList;
        } catch (error) {
            console.log("Unable to get All Companies, see error below:");
            console.log(error);
            throw error;
        }
    }

    /**
     * Retrieves a list of companies.
     * @async
     * @returns {Array} The list of companies.
     * @throws {Error} If an error occurs during the request.
     */
    async getAllCompaniesCopyright() {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/base/cr-all-companies");
            const results = await axios.get(connectionString, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return results.data.companyList;
        } catch (error) {
            console.log("Unable to get All Companies, see error below:");
            console.log(error);
            throw error;
        }
    }

    /**
     * Retrieves a list of marketplaces by company name.
     * @async
     * @param {string} companyName - The name of the company.
     * @returns {Array} The list of marketplaces.
     * @throws {Error} If an error occurs during the request.
     */
    async getMarketplacesByCompanyCopyright(companyName) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString(`/base/cr-marketplaces-by-company?companyName=${encodeURIComponent(companyName)}`);
            const results = await axios.get(connectionString, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return results.data.results.marketplaceList;
        } catch (error) {
            console.log("Unable to get Marketplaces for Company, see error below:");
            console.log(error);
            throw error;
        }
    }

    /**
     * Retrieves a list of marketplaces by company name.
     * @async
     * @param {string} companyName - The name of the company.
     * @returns {Array} The list of marketplaces.
     * @throws {Error} If an error occurs during the request.
     */
    async getMarketplacesByCompany(companyName) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString(`/base/marketplaces-by-company?companyName=${encodeURIComponent(companyName)}`);
            const results = await axios.get(connectionString, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return results.data.results.marketplaceList;
        } catch (error) {
            console.log("Unable to get Marketplaces for Company, see error below:");
            console.log(error);
            throw error;
        }
    }
}
