import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import FormHeader from "../../Headers/FormHeader";
import IndexNavbar from "../../Navbars/IndexNavbar";
import { CopyrightHandler } from "../../../handlers/CopyrightHandler"; 

class CopyrightComparisonsUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      selectedFile: null,
      loading: false,
      uploadSuccess: false,
      uploadError: null,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file); // Debugging log to confirm file selection
    this.setState({ selectedFile: file, uploadError: null });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { selectedFile, user } = this.state;

    if (!selectedFile) {
      this.setState({ uploadError: "Please select a file to upload." });
      return;
    }

    const copyrighthandler = new CopyrightHandler(user.email); // Pass user email to handler
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("email", user.email);

    this.setState({ loading: true, uploadSuccess: false });

    try {
      const response = await copyrighthandler.uploadFile(formData);
      console.log("File uploaded successfully:", response);
      this.setState({ uploadSuccess: true, selectedFile: null });
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "Failed to upload file. Please try again.";
      console.error("Error uploading file:", errorMessage);
      this.setState({ uploadError: errorMessage });
    } finally {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    return (
      <>
        <IndexNavbar />
        <FormHeader title="Upload Your File for Copyright Comparisons" />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <big>We will make update as you upload.</big>
                    <br/>
                    <big>Hold tight!</big>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-7 py-lg-3">
                  <Form onSubmit={this.handleSubmit}>
                    <Input
                      type="file"
                      onChange={this.handleFileChange}
                      onClick={() => console.log('Input clicked')} // Debugging log to confirm click
                      accept=".csv, .xlsx, .txt, .pdf" // Update with accepted file types as necessary
                      required
                      disabled={this.state.loading} // Make sure this is false when not loading
                    />

                    {this.state.uploadError && (
                      <div className="text-danger my-2">{this.state.uploadError}</div>
                    )}

                    {this.state.uploadSuccess && (
                      <div className="text-success my-2">Successfully updated Database!</div>
                    )}

                    <Button
                      className="my-4"
                      color="primary"
                      type="submit"
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? <Spinner size="sm" /> : "Upload File"}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CopyrightComparisonsUpload;
