/*
    Quick class for providing the server connection string
*/

//imported modules

export class ApiConnection {
    /**
     * Get server connection string
     * @param {string} route - The specific api route, returns the local host if in development, or 
     * the production server if in production
     * @returns 
     */
    getServerConnectionString(route){
        // Comment the line out below if pushing to production
        if(process.env.NODE_ENV === "development"){
            route = "http://localhost:4000" + route;
        }
        return route
    }
}