import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDgmC3n5-Gjvl-WG4cj-5T7YTO99W6402Y",
  authDomain: "gf-admins.firebaseapp.com",
  projectId: "gf-admins",
  storageBucket: "gf-admins.appspot.com",
  messagingSenderId: "115827602946",
  appId: "1:115827602946:web:90d13043c49436f2a4991b",
  measurementId: "G-9RPQ0TXNK8",
  databaseURL: "https://gf-admins-default-rtdb.firebaseio.com",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;
export const db = firebase.database();
export const firestore = firebase.firestore();

console.log('Firebase Auth:', auth);
console.log('Firebase Firestore:', firestore);