import { Container, Row, Col } from 'reactstrap';
import IndexNavbar from '../../components/Navbars/IndexNavbar';

function UnderConstruction(props) {
    const toolConstructionString = `This app is under construction.`;
    return (
    <>
        <IndexNavbar />
        <Container className='bg-white rounded py-5 mt-4'>
            <Row>
                <Col lg="12" md="12" className="d-flex justify-content-center">
                    <h1>{toolConstructionString}</h1>
                </Col>
                <Col lg="12" md="12" className="d-flex justify-content-center">
                    <p>Click <a href="/"><strong>here</strong></a> to return to the home page</p>
                </Col>
            </Row>
                
        </Container>
    </>
    )
}

export default UnderConstruction;