import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import FormHeader from "../Headers/FormHeader";
import IndexNavbar from "../Navbars/IndexNavbar";

const SuccessPage = () => {
  return (
    <>
      <IndexNavbar />
      <FormHeader title="Success!" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <big>Identifiers request has been successfully sent. You will receive an email with a link to download the data shortly.</big>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SuccessPage;
