import { ApiConnection } from "./ApiConnections";
const axios = require("axios").default;
const RUNNING_LOCALLY = false;

export class AutoCreateWalmartHandler {
    constructor(email) {
        this.userEmail = email;
        this.apiConnection = new ApiConnection();
    }

    async hasActiveRequest() {
        try {
            let url = this.apiConnection.getServerConnectionString(`auto-wal-create/status`);
            if (RUNNING_LOCALLY){
                url = "http://localhost:4000/auto-wal-create/status"
            }
            const header = {
                "User-ID": this.userEmail,
            }
            const config = {
                headers: header,
            }
            let results = await axios.get(url, config)
            console.log(this.userEmail, results.data.success, results.data.activeStatus, results.data.requiresUpdate)
            return [results.data.activeStatus, results.data.requiresUpdate]
        }
        catch(error) {
            throw error;
        }
    }

    async endLog() {
        try {
            let url = this.apiConnection.getServerConnectionString(`auto-wal-create/end`);
            if (RUNNING_LOCALLY){
                url = "http://localhost:4000/auto-wal-create/end"
            }
            const header = {
                "User-ID": this.userEmail,
            }
            const config = {
                headers: header,
            }

            let results = await axios.get(url, config)
            if (!results.data.success) {
                console.log(results.data.error)
                return results.data.success
            }
            return results.data.success
        }
        catch(error) {
            throw error;
        }
    }

    async isRequestComplete() {
        try {
            let url = this.apiConnection.getServerConnectionString(`auto-wal-create/iscomplete`);
            if (RUNNING_LOCALLY){
                url = "http://localhost:4000/auto-wal-create/iscomplete"
            }
            const header = {
                "User-ID": this.userEmail,
            }
            const config = {
                headers: header,
            }
            let results = await axios.get(url, config)
            
            if (results.data.success === true ){
                return results.data.is_request_completed
            }
            else {
                return false
            }
        }
        catch(error) {
            throw error;
        }
    }

    async getActiveRequestLog() {
        try {
            let url = this.apiConnection.getServerConnectionString(`auto-wal-create/log`);
            if (RUNNING_LOCALLY){
                url = "http://localhost:4000/auto-wal-create/log";
            }
            const header = {
                "User-ID": this.userEmail,
            }
            const config = {
                headers: header,
            }
            let results = await axios.get(url, config);
            
            return results.data.logs;
            // return temp_results // need to change to results returned
        }
        catch(error) {
            throw error;
        }
    }

    async requestAutoAccountCreation(accountCount, accountType, accountMarketplace, accountEmailDomain) {
        try {
            let url = this.apiConnection.getServerConnectionString(`auto-wal-create/request`);
            if (RUNNING_LOCALLY){
                url = "http://localhost:4000/auto-wal-create/request";
            }
            const body = {
                accountCount: accountCount, 
                accountType: accountType, 
                accountMarketplace: accountMarketplace, 
                accountEmailDomain: accountEmailDomain
            }
            const header = {
                "User-ID": this.userEmail,
            }
            const config = {
                headers: header,
            }
            // console.log(config)
            // console.log(body)
            let results = await axios.post(url, body, config);
            console.log(results);
            return results.data.success;
            
        }
        catch(error) {
            throw error;
        }
    }

    async checkAvailableProxies(accountCount, accountMarketplace) {
        try {
            let url = this.apiConnection.getServerConnectionString(`auto-wal-create/proxy`);
            if (RUNNING_LOCALLY){
                url = "http://localhost:4000/auto-wal-create/proxy";
            }
            const body = {
                accountCount: accountCount, 
                accountMarketplace: accountMarketplace
            }
            const header = {
                "User-ID": this.userEmail,
            }
            const config = {
                headers: header,
            }
            // console.log(config)
            // console.log(body)
            let results = await axios.post(url, body, config);
            if (results.data.success === true) {
                return [results.data.enoughProxies, results.data.amountAvailable]
            }
            else {
                console.log(results.data.error)
                return [results.data.success, -1]
            }
            
        }
        catch(error) {
            throw error;
        }
    }
}
