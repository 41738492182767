import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
import FormHeader from "../../Headers/FormHeader";
import IndexNavbar from "../../Navbars/IndexNavbar";
import { CompanyHandler } from "../../../handlers/CompanyHandler";
import { CopyrightHandler } from "../../../handlers/CopyrightHandler";
import { withRouter } from 'react-router-dom';

class CopyrightComparisonsSend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      companies: [],
      marketplaces: [],
      selectedCompany: '',
      selectedMarketplace: '',
      loading: false // Add loading state
    };
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    const userEmail = this.state.user.email;
    const companyHandler = new CompanyHandler(userEmail);

    try {
      const companies = await companyHandler.getAllCompaniesCopyright();
      if (this._isMounted) {
        this.setState({ companies });
      }
    } catch (error) {
      console.error("Unable to fetch companies:", error);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCompanyChange = async (event) => {
    const selectedCompany = event.target.value;
    this.setState({ selectedCompany, selectedMarketplace: '', marketplaces: [] });

    if (selectedCompany) {
      const companyHandler = new CompanyHandler(this.state.user.email);
      try {
        const marketplaces = await companyHandler.getMarketplacesByCompanyCopyright(selectedCompany);
        if (this._isMounted) {
          this.setState({ marketplaces });
        }
      } catch (error) {
        console.error("Unable to fetch marketplaces:", error);
        if (this._isMounted) {
          this.setState({ marketplaces: [] });
        }
      }
    } else {
      this.setState({ marketplaces: [] });
    }
  }


  handleMarketplaceChange = (event) => {
    this.setState({ selectedMarketplace: event.target.value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { selectedCompany, selectedMarketplace, user } = this.state;
    const copyrightHandler = new CopyrightHandler(user.email);

    const data = {
      companyName: selectedCompany,
      marketplaceName: selectedMarketplace,
      email: user.email
    };

    this.setState({ loading: true }); // Set loading to true when form is submitted

    try {
      const response = await copyrightHandler.sendComparisons(data);
      console.log("Comparisons sent successfully:", response);
      this.props.history.push('/success');
    } catch (error) {
      console.error("Error sending comparisons:", error);
      // Handle the error (e.g., display an error message)
    } finally {
      if (this._isMounted) {
        this.setState({ loading: false }); // Set loading to false after the API call
      }
    }
  }

  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        <IndexNavbar />
        <FormHeader title="Send yourself image comparisons!" />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <big>
                      We will compile the copyright images comparisons for you. Shortly after, you will receive an email to download the data.
                    </big><br></br>
                    <small>Important Note: Files may be 'exceedingly large in stature'. Therefore, we may break up the data into several files.</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-7 py-lg-3">
                  <Form onSubmit={this.handleSubmit}>
                    <input
                      type="hidden"
                      id="id"
                      name="id"
                      value={this.state.user.uid}
                    ></input>

                    <input
                      type="hidden"
                      id="email"
                      name="email"
                      value={this.state.user.email}
                    ></input>

                    <input
                      type="hidden"
                      id="name"
                      name="name"
                      value={user}
                    ></input>

                    <div className="text-center">
                      <div className="my-4">
                        <Input
                          type="select"
                          id="companyName"
                          name="companyName"
                          value={this.state.selectedCompany}
                          onChange={this.handleCompanyChange}
                          required
                        >
                          <option value="">--Select a company--</option>
                          {this.state.companies.map(company => (
                            <option key={company.id} value={company.name}>
                              {company.name}
                            </option>
                          ))}
                        </Input>
                      </div>

                      <div className="my-4">
                        <Input
                          type="select"
                          id="marketplaceName"
                          name="marketplaceName"
                          value={this.state.selectedMarketplace}
                          onChange={this.handleMarketplaceChange}
                          required
                          disabled={!this.state.marketplaces.length}
                        >
                          <option value="">--Select a marketplace--</option>
                          {this.state.marketplaces.map(marketplace => (
                            <option key={marketplace.id} value={marketplace.name}>
                              {marketplace.name}
                            </option>
                          ))}
                        </Input>
                      </div>

                      <Button className="my-4" color="warning" type="submit" disabled={this.state.loading}>
                        {this.state.loading ? <Spinner size="sm" /> : 'Send me comparisons!'}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(CopyrightComparisonsSend);
