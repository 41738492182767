import axios from 'axios';
import { ApiConnection } from "./ApiConnections";

/**
 * @class CopyrightHandler
 * @description Class to handle requests related to copyright comparisons
 * 
 * @constructor
 * @param {string} email - The email of the user making the request.
 * @property {string} userEmail - The email of the user making the request.
 * @property {ApiConnection} apiConnection - The ApiConnection object used to determine the server connection string.
 */
export class CopyrightHandler {
    /**
     * Creates an instance of IdentifiersHandler.
     * @param {string} email - The user's email.
     */
    constructor(email) {
        this.userEmail = email; // Store the provided email in the userEmail attribute
        this.apiConnection = new ApiConnection(); // Create an instance of the ApiConnection class and store it in the apiConnection attribute
    }

    /**
     * Sends a request to get new identifiers.
     * @async
     * @param {Object} data - The data to send in the request.
     * @returns {Object} The response from the server.
     * @throws {Error} If an error occurs during the request.
     */
    async sendComparisons(data) {
        console.log(data)
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/copyright/send");
            const response = await axios.post(connectionString, data, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to send comparisons request, see error below:");
            console.log(error);
            throw error;
        }
    }

     /**
     * Sends a request to get new identifiers.
     * @async
     * @param {Object} data - The data to send in the request.
     * @returns {Object} The response from the server.
     * @throws {Error} If an error occurs during the request.
     */
     async sendExternalComparisons(data) {
        console.log(data)
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/copyright/ext-send");
            const response = await axios.post(connectionString, data, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to send comparisons request, see error below:");
            console.log(error);
            throw error;
        }
    }

    // /**
    //  * Uploads a file and transforms it.
    //  * @async
    //  * @param {FormData} formData - The form data containing the file and additional fields.
    //  * @returns {Object} The response from the server.
    //  * @throws {Error} If an error occurs during the request.
    //  */
    // async uploadFile(formData) {
    //     try {
    //         let connectionString = this.apiConnection.getServerConnectionString("/copyright/upload");
    //         const response = await axios.post(connectionString, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 'User-ID': this.userEmail,
    //             }
    //         });
    //         return response.data;
    //     } catch (error) {
    //         console.log("Unable to upload file, see error below:");
    //         console.log(error);
    //         throw error;
    //     }
    // }

    async uploadFile(formData) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/copyright/upload");
            const response = await axios.post(connectionString, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to upload file, see error below:");
            
            // Capture the error message from the server response
            if (error.response) {
                // Server responded with a status other than 2xx
                const serverMessage = error.response.data.message || error.response.data.error || "An unknown error occurred.";
                console.error(`Server Error: ${serverMessage}`);
                throw new Error(serverMessage);
            } else if (error.request) {
                // Request was made but no response received
                console.error('No response received from the server.');
                throw new Error('No response received from the server. Please try again later.');
            } else {
                // Something else happened during the request setup
                console.error(`Error in request setup: ${error.message}`);
                throw new Error(`Error in request setup: ${error.message}`);
            }
        }
    }

    async uploadExternalCopyright(formData) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/copyright/ext-upload");
            const response = await axios.post(connectionString, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to upload file, see error below:");
            
            // Capture the error message from the server response
            if (error.response) {
                // Server responded with a status other than 2xx
                const serverMessage = error.response.data.message || error.response.data.error || "An unknown error occurred.";
                console.error(`Server Error: ${serverMessage}`);
                throw new Error(serverMessage);
            } else if (error.request) {
                // Request was made but no response received
                console.error('No response received from the server.');
                throw new Error('No response received from the server. Please try again later.');
            } else {
                // Something else happened during the request setup
                console.error(`Error in request setup: ${error.message}`);
                throw new Error(`Error in request setup: ${error.message}`);
            }
        }
    }

}
