import React, { Component } from 'react';
import { auth } from "../../services/firebase";
import { db } from "../../services/firebase";
import { cancelContainerRun } from "../../helpers/db";
import {
    Table,
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import FormHeader from "../Headers/FormHeader.js";
import IndexNavbar from "../Navbars/IndexNavbar.js";

export default class StatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      files: [],
      content: "",
      readError: null,
      writeError: null,
      loadingfiles: false,
    };
    this.cancelRun = this.cancelRun.bind(this);
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingfiles: true });
    try {
      db.ref("files").on("value", (snapshot) => {
        let files = [];
        snapshot.forEach((snap) => {
          if (snap.val().user === auth().currentUser.uid) {
            files.push(snap.val());
          }
        });
        files.sort(function (a, b) {
          return new Date(b.upload_time) - new Date(a.upload_time);
        });
        // this.state.files2 = []
        // files.forEach((file) => {
        //   console.log(file);
        // });
        this.setState({ files });
        // chatArea.scrollBy(0, chatArea.scrollHeight);
        this.setState({ loadingfiles: false });
      });
    } catch (error) {
      this.setState({ readError: error.message, loadingfiles: false });
    }
  }

  cancelRun = (e) => {
      var id = e.target.value.replace(".", "");
      cancelContainerRun(id).then(() => {
          console.log("Cancelling")
      });
      console.log(id)

    // this.setState({...this.state.request_data, [e.data.target]: e.data.value})

  };

  formatTime(timestamp) {
    const d = new Date(timestamp);
    console.log(d);
    const time = `${d.getDate()}/${
      d.getMonth() + 1
    }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    return time;
  }

  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
      // console.log(this.state.user.displayName);
    } catch (err) {
      console.log(err);
    }
    return (
      <>
        <IndexNavbar />
        <FormHeader title="Tool Use History" />
        <Container className="mt--9 pb-5">
          <Row className="justify-content-center">
            <Col lg="12" md="10">
              <small>Uploaded By: {user}</small>
              {/* loading indicator */}
              {this.state.loadingfiles ? (
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="bg-secondary"
                >
                  <thead>
                    <tr>
                      <th>Filename</th>
                      <th>Type</th>
                      <th>Datetime Uploaded</th>
                      <th>Status</th>
                      <th>Cancel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* chat area */}
                    {this.state.files.map((file) => {
                      return (
                        <tr>
                          <td>{file.filename}</td>
                          <td>{file.type}</td>
                          <td>{file.upload_time}</td>
                          <td>{file.status}</td>
                          {file.status === "In Progress" ||
                          file.status === "Queueing" ? (
                            <td>
                              <Button
                                className="my-0"
                                color="danger"
                                type="cancel"
                                value={file.blob}
                                onClick={this.cancelRun}
                              >
                                Cancel
                              </Button>
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}