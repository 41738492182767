import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import IndexNavbar from "../../Navbars/IndexNavbar";
import FormHeader from "../../Headers/FormHeader";
import { Link } from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Container} from "reactstrap";

class DbEditorMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
        };
    }

    render() {
        return (
        <>
            <IndexNavbar />
            <FormHeader title={"Database Editor"} lead="Please choose one of the following actions." />
            <Container fluid className="mt--8 pb-5">
                <div className="header-body">
                    <Row className="justify-content-center">
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/update-asins" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Existing Brands
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">Add/Update ASINs</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-gray text-white rounded-circle shadow">
                                            <i className="ni ni-lock-circle-open" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* Parse through the most recently retrieved sunday listings to see if they're related to the specified brand */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/update-apr-sellers" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Existing Brands
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">Add/Update Approved Sellers</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
                                            <i className="ni ni-lock-circle-open" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* View all pdp listings that have been uploaded to the database. (under construction)  */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/stand-alone-images" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Existing Brands
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">Add Stand-Alone Images for Brand</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-purple text-white rounded-circle shadow">
                                            <i className="ni ni-lock-circle-open" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* View all pdp listings that have been uploaded to the database. (under construction)  */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/db-status" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">View status of submissions</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i className="ni ni-lock-circle-open" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* View all pdp listings that have been uploaded to the database. (under construction)  */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                        
                    </Row>
                </div>
            </Container>
        </>
        )
    }
}

export default DbEditorMenu;