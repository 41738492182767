/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { auth } from "../../services/firebase";
import { signup, signInWithGoogle, assignName } from "../../helpers/auth";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "../../components/Headers/AuthHeader.js";
import AuthNavbar from "../../components/Navbars/AuthNavbar";

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      name: "",
      email: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.googleSignIn = this.googleSignIn.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await signup(this.state.email, this.state.password);
      const user = auth().currentUser;
      await assignName(user, this.state.name);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  async googleSignIn() {
    try {
      await signInWithGoogle();
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {
    return (
      <>
        <AuthNavbar />
        <AuthHeader
          title="Register"
          lead="Fill out the form below to create a new account, or use Google sign-in"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      onClick={this.googleSignIn}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={
                            require("../../assets/img/icons/common/google.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Or sign up with credentials</small>
                  </div>
                  <Form noValidate onSubmit={this.handleSubmit} role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        // focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control"
                          placeholder="Name"
                          name="name"
                          type="name"
                          onChange={this.handleChange}
                          value={this.state.name}
                        />

                        {/* <Input
                            placeholder="Email"
                            type="email"
                            //   onFocus={() => setfocusedEmail(true)}
                            //   onBlur={() => setfocusedEmail(true)}
                          /> */}
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames("mb-3", {
                        // focused: focusedEmail,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          type="email"
                          onChange={this.handleChange}
                          value={this.state.email}
                        />

                        {/* <Input
                            placeholder="Email"
                            type="email"
                            //   onFocus={() => setfocusedEmail(true)}
                            //   onBlur={() => setfocusedEmail(true)}
                          /> */}
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        // focused: focusedPassword,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                          type="password"
                        />

                        {/* <Input
                          placeholder="Password"
                          type="password"
                        //   onFocus={() => setfocusedPassword(true)}
                        //   onBlur={() => setfocusedPassword(true)}
                        /> */}
                      </InputGroup>
                    </FormGroup>
                    {/* <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor=" customCheckLogin"
                        >
                          <span className="text-muted">Remember me</span>
                        </label>
                      </div> */}
                    <div className="text-center">
                      {this.state.error ? (
                        <p className="text-danger">{this.state.error}</p>
                      ) : null}
                      <Button className="my-4" color="info" type="submit">
                        Register
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="mailto:it@grayfalkon.com"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a className="text-light" href="register">
                    <small>Already have an account?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
