import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import FormHeader from "../../Headers/FormHeader";
import IndexNavbar from "../../Navbars/IndexNavbar";
import { IdentifiersHandler } from "../../../handlers/IdentifiersHandler";
import { withRouter } from "react-router-dom";

class IdentifierUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      file: null,
      loading: false,
    };
  }

  handleFileChange = (event) => {
    this.setState({ file: event.target.files[0] });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("recfile", this.state.file); // This field name must match the Multer field name
    formData.append("id", this.state.user.uid);
    formData.append("email", this.state.user.email);
    formData.append("name", this.state.user.displayName || this.state.user.email);

    const identifiersHandler = new IdentifiersHandler(this.state.user.email);

    try {
      const response = await identifiersHandler.uploadAndTransform(formData);

      // Get the transformed file URL and download it as a Blob
      const transformedFileUrl = response.fileUrl;
      const transformedFileName = `from_rogue_search_${this.state.file.name}`;
      const transformedFileBlob = await fetch(transformedFileUrl).then(res => res.blob());

      // Create new FormData for the ASINs upload
      const asinFormData = new FormData();
      asinFormData.append("recfile", transformedFileBlob, transformedFileName); // This field name must match the Multer field name
      asinFormData.append("id", this.state.user.uid);
      asinFormData.append("email", this.state.user.email);
      asinFormData.append("name", this.state.user.displayName || this.state.user.email);

      await identifiersHandler.uploadASINsFile(asinFormData);

      this.props.history.push("/db-status");
    } catch (error) {
      console.error("There was an error uploading the file!", error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const buttonText = loading ? <Spinner size="sm" /> : "Submit identifiers!";
    return (
      <>
        <IndexNavbar />
        <FormHeader title="Upload your edited file!" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <big>
                      Upload a CSV or Excel file below to submit <br /><b>new identifiers</b><br /> in the database.
                    </big>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-7 py-lg-3">
                  <Form onSubmit={this.handleSubmit}>
                    <div className="text-center">
                      <Input
                        className="my-4"
                        type="file"
                        name="recfile" // This field name must match the Multer field name
                        placeholder="Select file"
                        accept=".csv,.xls,.xlsx"
                        onChange={this.handleFileChange}
                        required
                      />
                      <Button className="my-4" color="warning" type="submit" disabled={loading}>
                        {buttonText}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(IdentifierUpload);
