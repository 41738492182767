import { Component } from "react";
import { 
    Container, 
    Row, 
    Table, 
    // Col, 
    // UncontrolledDropdown, 
    // DropdownToggle, 
    // DropdownMenu,
    // Button
} from "reactstrap";
import { auth } from "../../../services/firebase";
// import Popup from "reactjs-popup";

function ArchiveTableHeader(props) {
    return (
        <thead>
            <tr>
                <th>
                    Date
                </th>
                <th>
                    Req #
                </th>
                <th>
                    Amazon Alias
                </th>
                <th>
                    First Name
                </th>
                <th>
                    Last Name
                </th>
                <th>
                    Phone #
                </th>
                <th>
                    Email Address 
                </th>
                <th>
                    Created
                </th>
                <th>
                    Two-Step Auth
                </th>
            </tr>
        </thead>
    );
}

class ActiveLogBody extends Component {
    constructor(props) {
        super(props);
        // console.log("PROPS", props)
        this.state = {
            user: auth().currentUser
        }
    }

    colorCodeBasic(status) {
        console.log(status)
        
        if (status === "pending") {
            return (
                <td className="PdpTableCell px-3 text-primary" >
                    {status}
                </td>
            )
        }
        else {
            return (
                <td className="PdpTableCell px-3 text-dark" >
                    <b>{status}</b>
                </td>
            )
        }
    }


    colorCodeComplete(status) {
        console.log(status)
        if (status === "COMPLETE") {
            return (
                <td className="PdpTableCell px-3 text-success" >
                    <b>{status}</b>
                </td>
            )
        }
        else if (status === "pending") {
            return (
                <td className="PdpTableCell px-3 text-primary" >
                    {status}
                </td>
            )
        }
        else {
            return (
                <td className="PdpTableCell px-3 text-info" >
                    <b>{status}</b>
                </td>
            )
        }
    }

    

    colorCodeEmail(status) {
        if (status === "pending") {
            return (
                <td className="PdpTableCell px-3 text-primary" >
                    <b>{status}</b>
                </td>
            )
        }
        else {
            return (
                <td className="PdpTableCell px-3 text-warning" >
                    {status}
                </td>
            )
        }
    }

    createTableBody(activeRequests) {
            return activeRequests.map((activeRequest) => (
            <tr key={activeRequest.count}>
                <td className="PdpTableCell">
                    {activeRequest.date}
                </td>
                <td className="PdpTableCell">
                    {activeRequest.count}
                </td>
                {/* <td className="PdpTableCell px-3">
                    {activeRequest.request_alias}
                </td> */}
                {/* <td className="PdpTableCell px-3" >
                    {activeRequest.email_status}
                </td> */}
                {this.colorCodeComplete(activeRequest.alias)}
                
                {this.colorCodeBasic(activeRequest.first_name)}
                {this.colorCodeBasic(activeRequest.last_name)}
                {this.colorCodeBasic(activeRequest.phone_number)}
                {this.colorCodeEmail(activeRequest.email_address)}
                {this.colorCodeComplete(activeRequest.amz_account_status)}
                {this.colorCodeComplete(activeRequest.two_step_auth_status)}
                {/* <td className="PdpTableCell px-3">
                    {activeRequest.amz_account_status}
                </td>
                <td className="PdpTableCell px-3">
                    {activeRequest.two_step_auth_status}
                </td> */}
            </tr>
        ));
    }


    render() {
        const activeLogTable = this.createTableBody(this.props.activeRequests);
        
        return (
            <> 
                <Row>
                    <Container className="archive-body my-2 rounded ">
                        <Row className="pl-3 py-3">
                            <strong>Request Logs</strong>
                        </Row>
                        <div className="AutoCreateTable">
                            <Table>
                                <ArchiveTableHeader/>
                                    <tbody>
                                        {activeLogTable}
                                    </tbody>
                            </Table>
                        </div>
                       
                    </Container>
                </Row>
            </>
        )
    }
}

export default ActiveLogBody;