import React, { Component } from "react";
import { auth } from "../../../services/firebase";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import FormHeader from "../../Headers/FormHeader";
import IndexNavbar from "../../Navbars/IndexNavbar";

export default class InsertStandAloneImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };
  }
  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
      // console.log(this.state.user.displayName);
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        <IndexNavbar />
        <FormHeader title="Stand-Alone Images Tool" />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <big>
                      Upload a CSV or Excel file below to submit <br/><b>stand-alone images</b><br/> in the database.
                    </big>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-7 py-lg-3">
                  <Form
                    action="/api/upload/standAloneImageData"
                    method="post"
                    enctype="multipart/form-data"
                  >
                
                    <input
                      type="hidden"
                      id="id"
                      name="id"
                      value={this.state.user.uid}
                    ></input>

                    <input
                      type="hidden"
                      id="email"
                      name="email"
                      value={this.state.user.email}
                    ></input>

                    <input
                      type="hidden"
                      id="name"
                      name="name"
                      value={user}
                    ></input>

                    <div className="text-center">
                      <Input
                        className="my-4"
                        type="file"
                        name="recfile"
                        placeholder="Select file"
                        accept=".csv,.xls,.xlsx"
                        required
                      />

                      <Button className="my-4" color="warning" type="submit">
                        Submit Images!
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
