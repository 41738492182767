import React, { Component } from 'react';
import { auth } from "../../../services/firebase";
import { db } from "../../../services/firebase";
// import { cancelContainerRun } from "../../../helpers/db";
import {
    Table,
    Container,
    Row,
    Col,
    Button
} from "reactstrap";
import FormHeader from "../../Headers/FormHeader.js";
import IndexNavbar from "../../Navbars/IndexNavbar.js";

export default class DBStatusPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
      files: [],
      content: "",
      readError: null,
      writeError: null,
      loadingfiles: false,
    };
    // this.cancelRun = this.cancelRun.bind(this);
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingfiles: true });
    try {
      db.ref("dbfiles").on("value", (snapshot) => {
        let files = [];
        snapshot.forEach((snap) => {
          if (snap.val().user === auth().currentUser.uid) {
            files.push(snap.val());
          }
        });
        files.sort(function (a, b) {
          return new Date(b.upload_time) - new Date(a.upload_time);
        });
        // this.state.files2 = []
        // files.forEach((file) => {
        //   console.log(file);
        // });
        this.setState({ files });
        // chatArea.scrollBy(0, chatArea.scrollHeight);
        this.setState({ loadingfiles: false });
      });
    } catch (error) {
      this.setState({ readError: error.message, loadingfiles: false });
      console.log(error)
    }
  }

  // cancelRun = (e) => {
  //     var id = e.target.value.replace(".", "");
  //     cancelContainerRun(id).then(() => {
  //         console.log("Cancelling")
  //     });
  //     console.log(id)

  //   // this.setState({...this.state.request_data, [e.data.target]: e.data.value})

  // };

  formatTime(timestamp) {
    const d = new Date(timestamp);
    console.log(d);
    const time = `${d.getDate()}/${
      d.getMonth() + 1
    }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    return time;
  }

  get_date_format(timestamp) {
    const myDate = new Date(timestamp);
    let month = myDate.getMonth() + 1;
    
    // helper function
    const addZeroIfNeeded = (num) => {
        return (num < 10) ? '0' + num : num.toString();
    }
    
    month = addZeroIfNeeded(month);
    let day = addZeroIfNeeded(myDate.getDate());
    
    let year = myDate.getFullYear();
    let hours = addZeroIfNeeded(myDate.getHours());
    let mins = addZeroIfNeeded(myDate.getMinutes());
    let seconds = addZeroIfNeeded(myDate.getSeconds());
    let mytime = myDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return `${month}/${day}/${year} ${mytime}`;
  }


  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
      // console.log(this.state.user.displayName);
    } catch (err) {
      console.log(err);
    }
    return (
      <>
        <IndexNavbar />
        <FormHeader title="DB Editor Submission Status" />
        <Container className="mt--9">
          <Row className="justify-content-center">
            <Col lg="12" md="5">
              <small>Uploaded By: {user}</small>
              {/* loading indicator */}
              {this.state.loadingfiles ? (
                <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  className="bg-secondary"
                >
                  <thead>
                    <tr>
                      <th>Filename</th>
                      <th>Type</th>
                      <th>Datetime Uploaded</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* chat area */}
                    {this.state.files.map((file) => {
                      return (
                        <tr>
                          <td>{file.filename}</td>
                          <td>{file.type}</td>
                          <td>{this.get_date_format(file.upload_time)}</td>
                          <td>{file.status}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}