import axios from 'axios';
import { ApiConnection } from "./ApiConnections";

/**
 * @class IdentifiersHandler
 * @description Class to handle requests related to identifiers
 * 
 * @constructor
 * @param {string} email - The email of the user making the request.
 * @property {string} userEmail - The email of the user making the request.
 * @property {ApiConnection} apiConnection - The ApiConnection object used to determine the server connection string.
 */
export class IdentifiersHandler {
    /**
     * Creates an instance of IdentifiersHandler.
     * @param {string} email - The user's email.
     */
    constructor(email) {
        this.userEmail = email; // Store the provided email in the userEmail attribute
        this.apiConnection = new ApiConnection(); // Create an instance of the ApiConnection class and store it in the apiConnection attribute
    }

    /**
     * Sends a request to get new identifiers.
     * @async
     * @param {Object} data - The data to send in the request.
     * @returns {Object} The response from the server.
     * @throws {Error} If an error occurs during the request.
     */
    async sendIdentifiers(data) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/identifiers/send");
            const response = await axios.post(connectionString, data, {
                headers: {
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to send identifiers request, see error below:");
            console.log(error);
            throw error;
        }
    }

    /**
     * Uploads a file and transforms it.
     * @async
     * @param {FormData} formData - The form data containing the file and additional fields.
     * @returns {Object} The response from the server.
     * @throws {Error} If an error occurs during the request.
     */
    async uploadAndTransform(formData) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/identifiers/uploadAndTransform");
            const response = await axios.post(connectionString, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to upload and transform file, see error below:");
            console.log(error);
            throw error;
        }
    }

    /**
     * Uploads ASINs file.
     * @async
     * @param {FormData} formData - The form data containing the file and additional fields.
     * @returns {Object} The response from the server.
     * @throws {Error} If an error occurs during the request.
     */
    async uploadASINsFile(formData) {
        try {
            let connectionString = this.apiConnection.getServerConnectionString("/api/upload/ASINsFile");
            const response = await axios.post(connectionString, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'User-ID': this.userEmail,
                }
            });
            return response.data;
        } catch (error) {
            console.log("Unable to upload ASINs file, see error below:");
            console.log(error);
            throw error;
        }
    }
}
