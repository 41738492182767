/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

import IndexNavbar from "../Navbars/IndexNavbar.js";
import FormHeader from "../Headers/FormHeader.js";
import { auth } from "../../services/firebase";

export default class Unauthorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };
  }
  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
    } catch (err) {
      console.log(err);
    }

    const pageText = `${user}, your account does not have the role required to access this tool.  If you believe this is an error, please contact your system adminstrator.`;
    return (
      <>
        <IndexNavbar />
        <FormHeader title="Error: Unauthorized User" lead={pageText} />
      </>
    );
  }
}
