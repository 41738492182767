import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/argon-dashboard-pro-react.css";
import "./assets/vendor/nucleo/css/nucleo.css";

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React>
//     <App />
//   </React>,
//   document.getElementById('root')
// );