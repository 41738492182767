import React, { Component } from "react";
import { auth } from "../../services/firebase";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import FormHeader from "../Headers/FormHeader.js";
import IndexNavbar from "../Navbars/IndexNavbar.js";

export default class ViolationSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().currentUser,
    };
  }
  render() {
    var user = this.state.user.email;
    try {
      user = this.state.user.displayName;
      // console.log(this.state.user.displayName);
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        <IndexNavbar />
        <FormHeader title="Violation Submission Tool" />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <small>
                      Upload a CSV or Excel file below to submit violations to
                      Amazon Brand Registry.
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form
                    action="/api/upload/uploadViolationFile"
                    method="post"
                    enctype="multipart/form-data"
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        id="client"
                        name="client"
                        placeholder="Customer Name"
                        required
                      ></Input>
                    </InputGroup>
                    <br></br>
                    {/* <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        placeholder={email}
                      ></Input>
                    </InputGroup>
                    <br></br> */}

                    <small>
                      <label for="MarketplaceSelect">
                        Marketplace Policy Violation Type
                      </label>
                    </small>

                    <select
                      class="form-control"
                      id="marketplace"
                      name="marketplace"
                      Report
                      a
                      Product
                      review
                      violation
                    >
                      <option value="described" selected>
                        Product not as described
                      </option>
                      <option value="variation">Incorrect variation</option>
                      <option value="other">
                        Other marketplace policy violations
                      </option>
                      <option value="trademark">Trademark violation</option>
                      <option value="copyright">Copyright violation</option>
                      <option value="reopen">Reopen a case</option>
                      <option value="tm_report_infringment">Report TM infringement (non-ABR)</option>
                      <option value="walmart">Walmart Copyright Filing</option>
                    </select>

                    <input
                      type="hidden"
                      id="id"
                      name="id"
                      value={this.state.user.uid}
                    ></input>

                    <input
                      type="hidden"
                      id="email"
                      name="email"
                      value={this.state.user.email}
                    ></input>

                    <input
                      type="hidden"
                      id="name"
                      name="name"
                      value={user}
                    ></input>

                    <div className="text-center">
                      <Input
                        className="my-4"
                        type="file"
                        name="recfile"
                        placeholder="Select file"
                        accept=".csv,.xls,.xlsx"
                        required
                      />

                      <Button className="my-4" color="info" type="submit">
                        Upload
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
